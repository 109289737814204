<template>
    <div>
        <v-card>
            <v-card-title class="title">
                <v-flex xs4 sm4>
                    <v-calendar-field
                            id-val="sod_date"
                            v-model="sdate"
                            label="Sod Date">
                    </v-calendar-field>

                </v-flex>
                <v-flex xs4 sm4>

                    <v-autocomplete
                            :search-input.sync="search"
                            :items="students"
                            @keyup="searchEnable=true"
                            @change="searchEnable=false"
                            hide-no-data
                            :loading="studentLoading"
                            class="pa-0"
                            label="Search Students"
                            v-model="name"
                            :hint="!students.length?'Type atleast 3 characters':''" outlined dense
                    />
<!--                    <v-text-field autocomplete="off" class="pa-0"-->
<!--                                  label="Search"-->
<!--                                  v-model="name" outlined dense/>-->
                </v-flex>
                <v-flex xs2>
                    <search-button style="" :disabled="!sdate || sdate.dateForm().length !== 10" permission="billing-report-collection"
                                   @action="getTodayStudentCollection()">
                        Search
                    </search-button>
                </v-flex>
                <v-spacer></v-spacer>
                <print-buttons :passAuthToReport="true" v-if="downloadUrl && studentsCollection.data.length > 0"
                               :download-url="downloadUrl"
                               :pdf="true" :excel="true"></print-buttons>

            </v-card-title>
        </v-card>
        <v-data-table :headers="studentHeaders"
                      :items="studentsCollection.data" :search="search"
                      :options.sync="pagination"
                      :footer-props="footerProps"
                      :server-items-length="studentsCollection.length > 0? studentsCollection.meta.total:0"
                      :loading="loading">
            <template  v-slot:item="{index, item}">
                <tr>
                    <td class="text-xs-left">{{index +1 }}</td>

                    <td class="text-xs-left">{{item.name}}
                        <br/><span>{{item.enroll_code}}</span></td>
                    <td class="text-xs-left">{{item.roll}}</td>
                    <td class="text-xs-left">{{item.grade}}<sup>"{{item.section}}"</sup></td>
                    <td class="text-xs-left">{{item.amount.currency()}}</td>
                </tr>
            </template>
            <template v-slot:body.append v-if="studentsCollection && studentsCollection.data && studentsCollection.data.length > 0">
                <tr>
                    <td colspan="4" class="text-xs-right"><strong>Total Amount:</strong></td>
                    <td><strong>{{totalSum.currency()}}</strong></td>
                </tr>
            </template>
<!--            <template v-slot:body.append-->
<!--                      v-if="studentsCollection && studentsCollection.data && studentsCollection.data.length > 0">-->
<!--                <tr>-->
<!--                    <td colspan="4" class="text-xs-right"><strong>Total Amount:</strong></td>-->
<!--                    <td><strong>{{totalSum.currency()}}</strong></td>-->
<!--                </tr>-->
<!--            </template>-->
        </v-data-table>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins'
    import {validateDate} from "../../../../../library/helpers";

    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    export default {
        mixins: [Mixins],
        data: () => ({
            search: null,
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            name: '',
            loading: false,
            totalSum: 0,
            studentsCollection: [],
            sdate: nd.format('YYYY-MM-DD'),
            students:[],
            studentLoading:false,
            // sdate: '2076-02-08',

            studentHeaders: [
                {text: 'S. No', align: 'left', value: 'id', sortable: false},
                {text: 'Name', align: 'left', value: 'name', sortable: false},
                {text: 'Roll', align: 'left', value: 'roll', sortable: false},
                {text: 'Grade', align: 'left', value: 'grade', sortable: false},
                {text: 'Paid Amount', align: 'left', value: 'amount', sortable: false}

            ],
            grades: [],
            grade: '',
            sections: [],
            section: '',
            collection: [],
            extra: [],
            totalCollectionAmount: 0,
            totalBillAmount: 0,
            totalInstantDiscountAmount: 0,
            downloadUrl: ''
        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
        },

        watch: {
            'name': function () {
                this.studentsCollection.data = [];
            },
            'sdate': function () {
                this.studentsCollection.data = [];
            },
            'paginationStudent': function () {
                if (![undefined, '', null].includes(this.studentsCollection.data) && this.studentsCollection.data.length > 0) {
                    this.getTodayStudentCollection();
                }
            },
            search(val) {
                if (!this.searchEnable) return false;
                if (!val) {
                    this.students = [];
                    this.studentLoading = false;
                    return;
                }
                if (this.isLoading) return;
                if (this.timer) {
                    clearTimeout(this.timer);
                    this.timer = null;
                }
                this.studentLoading = true;
                this.timer = setTimeout(() => {
                    this.$rest.get('api/search-student-grade-section?search=' + val)
                        .then(({data}) => {
                            this.students = data.data.map(item => {
                                return {
                                    value: item.enroll_code,
                                    text: item.name + ' (' + item.grade + '-' + item.section + ') - ' + item.roll,
                                    enrollCode: item.enroll_code
                                }
                            });
                            // console.log(data);
                            // this.remainingAdvance = data.data.advance
                        }).catch(err => {
                    }).finally(() => (this.studentLoading = false))
                }, 500);

            },

        },

        methods: {
            getTodayStudentCollection() {
                this.sdate = this.sdate.dateForm();
                if (this.sdate.length === 10) {
                    this.$rest.get('/api/report/sod-wise-transaction?sodDate=' + this.sdate + '&name=' + this.name + '&rowsPerPage=' + this.pagination.itemsPerPage).then(res => {
                        this.downloadUrl = (res.data.download_url);
                        this.studentsCollection = res.data;
                        if (this.studentsCollection.data.length < 0) this.studentsCollection.data.meta.total = 0;
                        this.totalSum = parseFloat(res.data.sum_amount);
                    })
                }

            },
        }
    }
</script>
<style lang="scss">
    .completed {
        border-radius: 11px;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 5px;
        padding-bottom: 5px;
        color: white;
        margin: 5px;
    }

    .bred {
        background: #ff5252;
    }

    .bgreen {
        background: #4caf50;
    }

    .byellow {
        background: #ff9800;
    }
</style>